import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VMain } from 'vuetify/lib/components/VMain';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,[(this.$store.getters.auth && this.$store.state.loggedInAs.type === 'organization')?_c('div',[_c('CmsPage',{attrs:{"pageKey":"Nav_Page_About"}})],1):_c(VContainer,{staticClass:"body"},[_c('h1',{staticClass:"display-1"},[_vm._v("About")]),_c('div',{staticClass:"text-center"},[_c('div',[_c('peeps-button',{staticClass:"mt-2 ml-2"}),_c('nitrate-button',{staticClass:"mt-2 ml-2"}),_c('privacy-button',{staticClass:"mt-2 ml-2"})],1)])]),_c(VFooter,[(_vm.buildDate)?_c('div',{staticClass:"build-date"},[_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'span',attrs,false),on),[_vm._v(" Updated "+_vm._s(_vm.fromNow(_vm.buildDate))+" ")])]}}],null,false,2623537817)},[_c('span',[_vm._v(_vm._s(_vm.format(_vm.buildDate, 'llll')))])])],1):_vm._e(),_c(VCol,{staticClass:"text-center"},[_c('p',[_vm._v(" Copyright © 2021 by World Wide Web Works LLC. All rights reserved, and no part of this website may be reproduced, stored in a retrieval system, or transmitted in any form or by any means-–electronic, mechanical, photocopying, recording, or otherwise–-without prior written consent of the copyright owner. ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }